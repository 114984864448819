<template>
  <div class="text-right">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          <v-icon left>mdi-plus</v-icon>
          <span>Добавить</span>
        </v-btn>
      </template>
      <v-card class="user-create-card px-4" outlined>
        <v-form ref="userForm" @submit.prevent="checkInput">
          <v-card-title>Добавить пользователя</v-card-title>
          <v-card-text>
            <span class="font-weight-medium">Имя пользователя</span>
            <v-text-field v-model="user.username" :rules="usernameRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense />
            <span class="font-weight-medium">Пароль</span>
            <v-text-field type="password" v-model="user.password" :rules="passwordRules" hide-details="auto" class="mt-1 mb-3" single-line outlined dense />
            <span class="font-weight-medium">Роль</span>
            <v-select v-model="user.roleId" :items="roles" item-text="title" item-value="id" hide-details class="mt-1" single-line outlined dense />
          </v-card-text>
          <v-card-actions class="mb-2">
            <v-spacer></v-spacer>
            <v-btn :loading="loading" type="submit" class="text-capitalize font-weight-regular" color="primary" depressed> Добавить </v-btn>
            <v-btn @click="closeDialog" class="mx-2 text-none font-weight-regular" color="grey darken-3" text>Отмена</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      user: this.createUserStub(),
      usernameRules: [(v) => !!v || 'Обязательное поле'],
      passwordRules: [(v) => !!v || 'Обязательное поле'],
      rules: [(v) => !!v || 'Обязательное поле']
    }
  },
  computed: {
    ...mapGetters('role', ['roles'])
  },
  methods: {
    createUserStub() {
      return {
        username: '',
        password: '',
        roleId: 2
      }
    },
    checkInput() {
      if (this.$refs.userForm.validate()) {
        this.loading = true
        this.submitUser()
      }
    },
    submitUser() {
      this.createUser(this.user)
        .then(() => {
          this.loading = false
          this.closeDialog()
        })
        .catch(() => {
          this.loading = false
        })
    },
    closeDialog() {
      this.dialog = false
      this.user = this.createUserStub()
      this.$refs.userForm.resetValidation()
    },
    ...mapActions('user', ['createUser'])
  }
}
</script>
