var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_c('h1',{staticClass:"subtitle-1 grey--text"},[_vm._v("Пользователи")]),_c('UserDialogCreate'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users},scopedSlots:_vm._u([{key:"item.username",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.username,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "username", $event)},"update:return-value":function($event){return _vm.$set(props.item, "username", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title"},[_vm._v("Имя пользователя")]),_c('v-text-field',{attrs:{"value":props.item.username,"rules":[_vm.max25chars],"label":"Введите имя пользователя","single-line":"","counter":"","autofocus":""}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.username)+" ")])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRoleById(item.roleId).title)+" ")]}},{key:"item.projects",fn:function(ref){
var item = ref.item;
return [(_vm.getRoleById(item.roleId).admin === 1)?[_vm._v(" Все ")]:[_c('v-chip-group',_vm._l((item.projects),function(project){return _c('v-chip',{key:project.id,attrs:{"to":{ name: 'project-kits', params: { projectId: project.id } },"label":"","small":""}},[_vm._v(" "+_vm._s(project.title)+" ")])}),1)]]}},{key:"item.password",fn:function(ref){return [_vm._v(" Изменить ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }