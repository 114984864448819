<template>
  <div class="users">
    <h1 class="subtitle-1 grey--text">Пользователи</h1>

    <UserDialogCreate />

    <v-data-table :headers="headers" :items="users">
      <template v-slot:item.username="props">
        <v-edit-dialog :return-value.sync="props.item.username" large persistent @save="save" @cancel="cancel" @open="open" @close="close">
          {{ props.item.username }}
          <template v-slot:input>
            <div class="mt-4 title">Имя пользователя</div>
            <v-text-field :value="props.item.username" :rules="[max25chars]" label="Введите имя пользователя" single-line counter autofocus></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.role="{ item }"> {{ getRoleById(item.roleId).title }} </template>
      <template v-slot:item.projects="{ item }">
        <template v-if="getRoleById(item.roleId).admin === 1"> Все </template>
        <template v-else>
          <v-chip-group>
            <v-chip v-for="project in item.projects" :key="project.id" :to="{ name: 'project-kits', params: { projectId: project.id } }" label small>
              {{ project.title }}
            </v-chip>
          </v-chip-group>
        </template>
      </template>
      <template v-slot:item.password="{}"> Изменить </template>
      <template v-slot:item.createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import UserDialogCreate from '@/components/user/UserDialogCreate'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    UserDialogCreate
  },
  data() {
    return {
      headers: [
        { text: 'Имя пользователя', align: 'start', value: 'username' },
        { text: 'Роль', align: 'start', value: 'role' },
        { text: 'Проекты', align: 'start', sortable: false, value: 'projects' },
        { text: 'Пароль', align: 'start', value: 'password' },
        { text: 'Дата создания', align: 'start', value: 'createdAt' },
        { text: 'Дата изменения', align: 'start', value: 'updatedAt' }
      ]
    }
  },
  mounted() {
    this.fetchUsers()
    this.fetchRoles()
  },
  computed: {
    ...mapGetters('user', ['users']),
    ...mapGetters('role', ['getRoleById'])
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('ddd, D MMMM YYYY')
    },
    ...mapActions('user', ['fetchUsers']),
    ...mapActions('role', ['fetchRoles'])
  }
}
</script>

<style></style>
